/* eslint-disable react/display-name */
import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import { Converter } from "showdown"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ImageMeta from "../components/ImageMeta"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

import SideCTA from "../components/CTA/SideCTA"

var showdown = require("showdown")

function createMarkup() {
  return { __html: "First &middot; Second" }
}

function MyComponent() {
  return <div dangerouslySetInnerHTML={createMarkup()} />
}

const SingleInstruction = ({ data, pageContext }) => {
  var post
  var language
  if (pageContext && pageContext.language) {
    language = pageContext.language
    switch (language) {
      case "en":
        post = data.allInstructionsJson.nodes[0]
        break
      case "es":
        post = data.allSpanishInstructionsJson.nodes[0]
        break
      default:
        post = data.allInstructionsJson.nodes[0]
    }
  } else {
    post = data.allInstructionsJson.nodes[0]
  }

  const converter = new showdown.Converter()
  var text = post.body
  var html = converter.makeHtml(text)

  function createBodyHtml() {
    return { __html: html }
  }

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          youtubeSchemas={pageContext.youtubeSchemasJson}
        />
        <div className="columns top-section green-color-back">
          <div className="column is-4"></div>
          <div className="column">
            <h1>{post.name}</h1>
            <Button
              buttonText={language === "es" ? "ATRÁS" : "BACK"}
              goBack
              href={language === "es" ? "/es/instrucciones/" : "/instructions/"}
            />
          </div>
          <div className="column is-4"></div>
        </div>
        <div className="body-section joshua-tree-content instruction-content">
          {post.youtube && post.mainPhotoPublicId && (
            <div className="columns instruction-image-video">
              <div className="column is-3"></div>
              <div className="column">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  overVideo={true}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={post.mainPhotoPublicId}
                    width="auto"
                    className="theater-image"
                  ></ImageMeta>
                </TheaterVideo>
              </div>
              <div className="column is-3"></div>
            </div>
          )}
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <div dangerouslySetInnerHTML={createBodyHtml()}></div>
            </div>
            <div className="column is-2"></div>
            <div className="column is-6">
              <SideCTA
                className=""
                sideColumnIs={0}
                subheading={language === "es" ? "Preguntas?" : "Questions?"}
                heading={
                  language === "es"
                    ? "Estamos Aquí Para Ayudar"
                    : "We Are Here To Help"
                }
                paragraph={
                  language === "es"
                    ? "Póngase en contacto con nuestra oficina si tiene alguna pregunta o problema con estas instrucciones."
                    : "Please contact our office if you have any questions or issues with these instructions."
                }
                buttonText={language === "es" ? "Contáctenos" : "Contact Us"}
                buttonUrl={
                  language === "es"
                    ? "/es/contactenos-oklahoma-city-ok/"
                    : "/contact-us-in-mount-pleasant-sc/"
                }
              />
              <SideCTA
                className=""
                sideColumnIs={0}
                subheading={
                  language === "es" ? "Versión PDF" : "Download Instructions"
                }
                heading={
                  language === "es"
                    ? "Instrucciones de Descarga"
                    : "Download Instructions"
                }
                paragraph={
                  language === "es"
                    ? "Sigue las instrucciones sobre la marcha. Descargar en formato PDF."
                    : "Take the instructions on the go. Download in PDF format."
                }
                buttonText={language === "es" ? "Descargar" : "Download"}
                buttonUrl={`/${post.pdfId}.pdf`}
                download
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query ($title: String!) {
    allInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
      }
    }
  }
`

export default SingleInstruction
